import React from "react";
import styled from "styled-components";

const PgpAcs = () => {
  return (
    <Wrapper>
      -----BEGIN PGP PUBLIC KEY BLOCK-----
      <p>
        <br />
        mQSuBGN5ETIRDACWqrjNmCG2OysxCXeUYjr/dCZwlvGgYys6AkcsUSrwm3QGlh8H
        rgg5hSijY7uJEKb8yqnobuoNEQbY9Shib3cxKb+xqGfACnh77STar0nT5HpAaBDA
        c0v+qprW/WAbYeBI+71CUHWQIOl9KpBGFYxb41zg7kiWrThxQjDWlN3jQRXfXTTr
        8NbvakJTBU587CpyUadrNCxxOkVaqPdsfwLoqSKNvYJp61GanLgyvMQ+LrdVcrO1
        +nTibKuLn9ao9UJOVDfVRxsO/MrwfVXSMNOjFiQtARNkvb2T3tDVRpXgj25H39Ra
        XHbP7r8i8Qh+8Uv9/RXwHCJANWc5XbroNy5rrvVumdsiuUyH0zJw9KNjpW+D6ln6
        0YKeBrxQCnCdSOD+96DhTR291EVjaXIMKT1ZmUsvQW++B0547l6YYONjA7ciiktc
        YZcPOyK5Gi9tQyyZalPV1zI56PpltEO6LJ4a0jrf4SKWfHSbhxZEKf9/B5Nhs79w
        jzaaedyOUg1FdE8BAOM3Ro0CzKN2JVhrHGAit/lfCfIteXT8PcpVwGTqKLw5C/9Y
        rhn7k8cFpT/h/aDXQ5IJyOLKNpKS5Kn1E6NC/SYyW+KylkNiK8/My2gAmxGbfZJd
        jWuBx4MEzcaPy2YLSKxCb8ICynfOJLkqhp2gCMszxjW/33NXn+0ENBaQRemGr1EW
        PpJihAoQv+rfgO7NDtXBFcf1yCrdHNemVaI6kZ08/tvmnUHXnmgPK7ITjB2lyIEq
        vZHz+H2F9+qg7q3Wi35+vy02/gJUpmMS3/21Q5OCdLLRikoeHKmsxowC3A76C735
        cnvCkp5UYlj8XVxTshFgpa8d0USaGnRm/VmaBhEtLGbwLId/Dzoh5HAvmio4rEQn
        lmwUIIHnnHodmQlSCJwMXc+CZvm6IVPLPJ3nfu738itP/OKDydbhxANJS1e5P4wz
        +Aw9Ixzde2bf5PkQifcGeS0XxvTyhdQitPVDBm2haV11Aq9PYNN1JiaIcS8cbMNg
        /DGJzTnevUmJK660mrak3IAEUP1cUSqlpBxDhMhscKiwu2GFxPgvVDjO+RI5jUcL
        /idyX5RL81FmDxs1lwi2REjlTnNU/cdr568Iw4LiVgReptAbuOnANR0qgxv+0hzl
        pLnEHPzqWkYx6Gj1OAdvIMPCU9mgO1dZO3a8EndvfBYK3aw2TlBFnS4tJeKTuWIm
        kXk7BLywRkwV2h9jKd39WVIiO+hGjbLQQSWUgzxPu+0KMTs8qhQ1t58cee9tCWKo
        vNevbBINALXsbbS+pf6tOuAeJfDFgH93xzyt+sEaaF9W/h3WPtnDue/6mePntsii
        DuYpsZQMorLZS05az2JGlayJI7UcvsLYwWx0K5MikJXnrl6zbHaWJK5KSlxKMpk2
        tmdawl15JErmrDuEfH0vGfH4gCQDIWqDMCKCSTUp6gJG9zb96TNTyVFRGxsY4Vu1
        K1Pvy6kCj/82zv2cXlvEo2oko36qwjxyjMO5pte5Ams9vYPmtBZkUOmrUvtpa7FH
        E6sHR7E+IbxDd1Sr9HxPyTWB7my4ismfLcyv/xJStUSnM9NcUQnVV5XnyPC00vgs
        FbQfUlNTSSBzbXNtb2RlIDxyc3NpQHNtc21vZGUuY29tPoiWBBMRCAA+FiEEojD5
        WUvbItgMUo+GNz+eqjBSG3YFAmN5ETICGwMFCQlmAYAFCwkIBwIGFQoJCAsCBBYC
        AwECHgECF4AACgkQNz+eqjBSG3ae9AD7B7Fj80EuvOcB2rCdpwkZbhl6i9bwFBDG
        lqj5K10fLIEBALXej8+fL6ziBU3f9irsA8uMSwbqJhzbBDuvsKnwOIZUuQMNBGN5
        ETIQDACRrXtRG2DkaYT0VPwfG/6wB1jYFDpNMYKJ1CJlCxpI1oGmMCd42bT6Ly64
        Z+bM5m7ZTqTFHoDqFbZdQ4eu794JeZm41fZX0G3TzaUkmFtC22AYl41rPCBwCuQo
        6P7O2n8xO6ta4ewkkigmXVCkjyyvCKkrnvwzG4tnBXmgQxCUNRmo6fzGlRRls6bh
        PKE7npfI5RFmMZUB+saf2ubLkYuBuxl9SXpJ6AMAA+V8TGiCtaXeJ17UiNmVmcNh
        AzEKydYWZR7jJPxXB+4YBO4mcrC5h5THcaXvBsUL6qJJ8Zm1J5noJpHd8zSQ8k4D
        oYBSsLdyg9rxuZRKXMlxHyXe0aecjlutGZsNcz0B5Zhq+pxETb9P9ML1KxkM9qBK
        u6y2BdYixUGggzv/Mvu9oUzjgL9zHFQnfqX39lJNQ9HUb7iO+g6Zi/xornDfWFh7
        5dFghSbb3WuSOMEIBfOFlpUh7RrLIhpvuwwI31Z/cNJsSWN2Ez1kymECnO/mIwYk
        h+b1RJMAAwUL/1e+hiMOc3aq9E5UKjHzXfyXor8aAU9Uayb4BOTbvw937UKdJjxe
        MKBoS5Ti8cp6y/CJ7nSbeby4ecHFDC8D6+b6yYAfguHW00yQeVfNTBVHm1K4vnWO
        ZMrKxzSS7FxJT1EABX1PaqTYH3hTi8BSQQ/E/iEbvuSyeibZSKS3EnUYtemBrjR/
        Q6VZzzCI7Y+kKh4wzFQESPRhzkS7oyBT7RkFi6RANJdYXDqYy3Wf4uJWkXWVHuvl
        kQ/0TjjiJEj+kwwqOsDzS5xcH5M55zDrduIpffvE+iBbcLBv6FMCMX15qfYnpYiL
        ss1y+5xcJW7ZxCfyQsVEWJsCbaI9TEDM81KJnODRfbvIBkBBRdTNf/0c/RhHL3Bs
        BmSi0cP3StmmIvA9/KvUsukNYvaNXYijIG7sY22f9Bfr4yxgV9DPaa7rbXzdT17V
        3jfVCUB3cxUqBmm5XuIdAt9wALdnzTtFGv3Pdo8i/dmhdcQcbVlBi7TbHzpqwvNM
        nEa9WgISpUi8FIh+BBgRCAAmFiEEojD5WUvbItgMUo+GNz+eqjBSG3YFAmN5ETIC
        GwwFCQlmAYAACgkQNz+eqjBSG3YPUAD+Me5XKnFDXEDyvjC1FkK/N8DQ2Q+p5ruE
        hRSABLBx8XYBAK1oauZ8gFZyy5jZFq5LOukFcbybuWLn4uZerKLKyCMY
        <br />
        =EJ1D
      </p>
      -----END PGP PUBLIC KEY BLOCK-----
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    max-width: 64ch;
    text-align: justify;
  }
`;

export default PgpAcs;
